import React, {useEffect, useState, useContext} from 'react';
import { Link } from "gatsby"
import useTranslations from "../../utils/useTranslations"
import { FaLinux } from 'react-icons/fa';
import Button from "../../components/button"
import fetchCherryData from "../../utils/cherry"


const DownloadsLinux = ({ pageContext: {text, locale} }) => {
const t = useTranslations(text)

	const [dataLinuxPlatforms, setDataLinuxPlatforms] = useState([]);

	useEffect(() => {
			fetchCherryData("linux", "linux", locale, "beta")
			.then((res) => {
				const linuxPlatforms = []

				res.forEach((platform) => {
					linuxPlatforms.push(createLinuxPlatform(platform))
				})

				setDataLinuxPlatforms(linuxPlatforms)
			})


		},[locale]
	)

	function createLinuxPlatform(data) {
		return (
			<div className="row mt-4">
				<div className="col-12 text-center text-md-left">
					<div className="mb-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<div className="d-flex flex-column flex-md-row ustify-content-center align-items-center">
		              		<p className="text-size-30 m-0">µTorrent Server for <span className="text-capitalize">{data.platform}</span></p>
		              	</div>
		              	<div className="flex-shrink-0">
							<Button href={data.link} id={`linux-downloads-${data.platform}`} className="btn-primary button-filled text-spacing-1 my-2 my-sm-0">{t("Free Download")}</Button>
		              	</div>
					</div>
					<p className="m-0"><span className="text-capitalize">{data.platform} - {(data.is64) ? '64' : '32'}-bit</span> ({data.fileSize})</p> 
					<p className="m-0 pb-4">{t("English (US) –")} {data.lastModified} </p>
					<hr style={{ margin: `10px 0 10px 0` }} />
				</div>
			</div>
		)
	}

	return (
		<div className="container my-5 pt-5">
			<div className="row">
				<div className="col-12 text-center text-md-left">
					<h1><FaLinux className="mb-2" />  <span className="ml-2">{t("µTorrent Downloads for Linux")}</span></h1>
					<div className="text-size-24 mt-2 mb-4 text-muted">{t("Download torrents on Linux. Supporting 64-bit or 32-bit for Debian or Ubuntu, find the right BitTorrent Linux torrent client to download.")}</div>
				</div>
			</div>
			{[dataLinuxPlatforms]}
		</div>
	)

}

export default DownloadsLinux